/* Tags.css */
.react-tagsinput-tag {
  background-color: #727272; /* New background color */
  border-radius: 5px; /* New border radius */
  border: 0px; /* New border color */
  color: #ffffff; /* New text color */
  padding: 6px;
}

.react-tagsinput {
  border: 1px solid #d5d7da;
  border-width: 1px;
  border-radius: 8px;
}
