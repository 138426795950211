body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6f7;
}

input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.mdxeditor {
  border: 1px solid #d5d7da;
  border-radius: 8px;
}

:root {
  --primary: #000000;
  --grey1: #f7f7f7;
  --grey2: #f3f3f3;
  --grey21: #e8e8e8;
  --grey3: #d9d9d9;
  --grey4: #c4c4c4;
  --grey5: #a8a8a8;
  --grey6: #8c8c8c;
  --grey7: #6d6d6d;
  --grey8: #4d4d4d;
  --grey9: #333333;
  --grey10: #1a1a1a;
  --text: #202020;
  --primary-admin: #000000;
  --card-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.06);
  --card-shadow-hover: 2px 2px 15px 0px rgba(0, 0, 0, 0.15);
  --sidebar-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.03);
}
